<template>
    <div>
        <div class="coupon-box" id="coupon-box">
          <div class="title">全部</div>
          <div class="block">
            <div class="row" v-for="(prompt) in allPrompt" :key="prompt.id" @click="goOutputPage(prompt)">
              <div class="img">
                <img :src="prompt.img" alt="" width="40">
              </div>
              <div class="content">
                <div class="text">{{ prompt.text }}</div>
                <div class="desc">{{ prompt.desc }}</div>
              </div>
            </div>
          </div>
        </div>
        <Main v-if="$route.path.includes('prompt') && $store.state.userAgent === 'Mobile'" @resetScrollTop="resetScrollTop"></Main>
    </div>
</template>

<script>
import Main from '../../components/footer/Main.vue';
import { wxSdkShare } from '@/utils/index';
const seo = require('../../../config/index')
import { allPrompt } from '../../assets/js/allPrompt'
// import { queryCouponById } from '@/api/api'

export default {
  name: 'Prompt',
  components: {
    Main
  },
  mounted(){
    this.wxShareInit()
  },
  data:()=>{
    return {
      scroll: 0,
      allPrompt: allPrompt
    }
  },
  methods:{
    resetScrollTop(){
        this.scroll = 0
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
    },
    goOutputPage(prompt){
      this.$router.push({ path:'/output', query:{ id: prompt.id } })
    },
    wxShareInit(){
      let params = {}
      params.url = seo.config.wxConfig.promptPage.link + this.$route.fullPath
      params.context = seo.config.wxConfig.promptPage.desc
      params.title = seo.config.wxConfig.promptPage.title
      params.imgUrl = seo.config.wxConfig.promptPage.imgUrl
      params.appId = seo.config.wxConfig.appId
      wxSdkShare(params)
    }
  }
}
</script>

<style scoped lang="scss">
.coupon-box {
  padding: 15px 15px 0;
  margin-bottom: calc(55px + constant(safe-area-inset-bottom));
  margin-bottom: calc(55px + env(safe-area-inset-bottom));
  .title {
    text-align: left;
  }
  .block {
    margin-top: 15px;
    .row {
      display: flex;
      align-items: center;
      .img {

      }
    }
    .content {
      text-align: left;
      padding: 10px;
      font-size: 14px;
      .text {
        margin: 0 0 2px 0;
      }
      .desc {
        font-size: 12px;
        color: #969699;
        display: -webkit-box;
        -webkit-box-orient: vertical;    
        -webkit-line-clamp: 1;  
        overflow: hidden;
      }
    }
  }
}
</style>