export const allPrompt = [{
    id:'dailyReport',
    img: require('../../assets/images/daily-report.png'),
    type:'',
    text: '日报',
    desc: '根据您今日的工作内容帮您生成今日日报'
  },{
    id:'weeklyReport',
    img: require('../../assets/images/weekly-report.png'),
    type:'',
    text: '周报',
    desc: '根据您本周的工作内容帮您生成本周周报'
  }
  // ,{
  //   id:'shortVideoScript',
  //   img: require('../../assets/images/short-video-script.png'),
  //   text: '短视频脚本',
  //   desc: '抖音/快手/小红书/微博，短视频创作'
  // }
  ,{
    id:'textOptimization',
    img: require('../../assets/images/text-optimization.png'),
    type:'',
    text: '文本优化',
    desc: '输入需要优化的文本，返回润色后的文本'
  },
  // {
  //   id:'reflectionMaterial',
  //   img: require('../../assets/images/reflection-material.png'),
  //   text: '检讨材料',
  //   desc: '根据您犯的错误，给你写一篇检讨材料'
  // },
  {
    id:'summay',
    img: require('../../assets/images/summay.png'),
    text: '总结主旨',
    desc: '根据您提供的文章，总结出主旨大意'
  },
  // {
  //   id:'googleTranslate',
  //   img: require('../../assets/images/google-translate.png'),
  //   text: '中英文翻译',
  //   desc: '提供地道的中英文翻译，专业词汇翻译'
  // },
  // {
  //   id:'baidubaike',
  //   img: require('../../assets/images/baidubaike.png'),
  //   type:'',
  //   text: '百度百科',
  //   desc: '根据您的问题，按百度百科的风格回答你'
  // },
  {
    id:'xiaohongshu',
    img: require('../../assets/images/xiaohongshu.png'),
    type:'',
    text: '小红书',
    desc: '帮助你生成小红书的Emoji风格文案'
  },
  // {
  //   id:'xiaohongshuTitle',
  //   img: require('../../assets/images/xiaohongshu.png'),
  //   text: '小红书标题',
  //   desc: '根据您想发布的内容，帮您生成6个标题'
  // }
  // ,{
  //   id:'dazhongdianpin',
  //   img: require('../../assets/images/dazhongdianpin.png'),
  //   text: '美团/大众点评',
  //   desc: '探店打卡，帮您生成美团/大众点评风格的文案'
  // },{
  //   id:'taobao',
  //   img: require('../../assets/images/taobao.png'),
  //   text: '京东/淘宝/拼多多',
  //   desc: '商品评价，帮您生成购物风格的文案'
  // },
  // {
  //   id:'zhihu',
  //   img: require('../../assets/images/zhihu.png'),
  //   type:'',
  //   text: '知乎问答',
  //   desc: '根据您的问题，按知乎的风格回答你'
  // },
  // ,{
  //   id:'moments',
  //   img: require('../../assets/images/moments.png'),
  //   text: '朋友圈营销文案',
  //   desc: '一键转换，生成带格式适合朋友圈传播文案'
  // },
  {
    id:'novel',
    img: require('../../assets/images/novel.png'),
    text: '小说',
    desc: '根据故事类型输出小说，例如奇幻、浪漫或历史等类型'
  },
  // {
  //   id:'writing',
  //   img: require('../../assets/images/writing.png'),
  //   text: '长文写作',
  //   desc: '用于撰写论文、调研报告等长文章'
  // },
  {
    id:'ppt',
    img: require('../../assets/images/ppt.png'),
    text: '生成PPT大纲 ',
    desc: '让AI生成主题大纲，然后将其放入指定Markdown格式中'
  },
  {
    id:'article',
    img: require('../../assets/images/article.png'),
    text: '内容改写',
    desc: '避免文本重复，大幅改写指定内容，确保伪原创有效'
  },
  {
    id:'cv',
    img: require('../../assets/images/cv.png'),
    type:'',
    text: '简历优化',
    desc: '帮助你写简历和优化简历，增加筛选通过率'
  },{
    id:'clapperboard',
    img: require('../../assets/images/clapperboard.png'),
    type:'',
    text: '写剧本',
    desc: '根据主体，创作包含故事背景、人物以及对话的剧本'
  },{
    id:'talkshow',
    img: require('../../assets/images/talkshow.png'),
    type:'',
    text: '脱口秀灵感',
    desc: '根据提供的话题，输出幽默的脱口秀'
  },
  // {
  //   id:'comfort',
  //   img: require('../../assets/images/comfort.png'),
  //   text: '哄女友',
  //   desc: '女友生气怎么办？用我帮您分析'
  // },{
  //   id:'blessing',
  //   img: require('../../assets/images/blessing.png'),
  //   text: '节日祝福',
  //   desc: '过节了，帮您写祝福消息'
  // },
  {
    id:'sleep',
    img: require('../../assets/images/sleep.png'),
    type:'',
    text: '解梦',
    desc: '根据您的描述，分析您当前的运势'
  },
  // {
  //   id:'feedback',
  //   img: require('../../assets/images/feedback.png'),
  //   text: '高情商回复',
  //   desc: '帮助您高情商的回复消息'
  // },{
  //   id:'angry',
  //   img: require('../../assets/images/angry.png'),
  //   text: '吵架助手',
  //   desc: '辅助你优雅的吵架，不说一个脏字'
  // }
  {
    id:'mjPromoteGeneration',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: 'Midjourney 提示生成器',
    desc: '填充创意描述，激发 Midjourney 生成独特图像'
  },
  {
    id:'nature',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: 'Nature 风格润色',
    desc: 'Nature 风格润色，或模仿其他写作风格'
  },
  {
    id:'paper',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '论文',
    desc: '深入主题，写具有说服力的详实论文'
  },
  {
    id:'paperResponse',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '论文式回答',
    desc: '用论文探讨问题，得到更高质量、连贯、结构化的回答'
  },
  {
    id:'confession',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '异性对话生成器',
    desc: '情感共鸣，延续话题，热情互动，避免尴尬'
  },
  // {
  //   id:'chatgptUnLimited',
  //   img: require('../../assets/images/blessing.png'),
  //   type:'',
  //   text: '无限制的ChatGPT（降权）',
  //   desc: '2023.06.10降权，超越DAN，解锁黑化，开发者模式'
  // },
  {
    id:'translate',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '英语翻译/修改',
    desc: '将其他语言翻译成英文，或改进你提供的英文句子'
  },
  {
    id:'narrationScript',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '口播脚本',
    desc: '撰写视频、直播、播客、分镜头和其他口语内容的脚本'
  },
  {
    id:'codingQA',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: 'IT 编程问题',
    desc: '模拟编程社区来回答你的问题，并提供解决代码'
  },
  {
    id:'imgResUnlock',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '解锁图片回复',
    desc: '输入图片链接或文字，如「"pig"」，回复中显示相关图片'
  },
  {
    id:'thinkTank',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '智囊团',
    desc: '给你提供多种不同的思考角度'
  },
  {
    id:'articleContinuation',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '文章续写',
    desc: '根据文章主题，延续文章开头部分来完成文章。'
  },
  {
    id:'DeepThinking',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '深度思考助手',
    desc: '根据关键词、主题或者概念，训练自己的深度思考能力'
  },
  {
    id:'titleGenerator',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '写作标题生成器',
    desc: '根据文章内容生成相应语言的标题'
  },
  // {
  //   id:'codeComment',
  //   img: require('../../assets/images/blessing.png'),
  //   type:'',
  //   text: '代码释义器',
  //   desc: '让AI解释每步代码的作用'
  // },
  {
    id:'promptImprove',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '提示词修改器',
    desc: '让机器人为我们重新撰写chatgpt提示词'
  },
  {
    id:'fitnessInstructor',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '健身教练',
    desc: '输入身高、体重、年龄等指标，来制定健身方案'
  },
  // {
  //   id:'AIDoctor',
  //   img: require('../../assets/images/blessing.png'),
  //   type:'',
  //   text: 'AI医生',
  //   desc: '辅助诊断'
  // },
  {
    id:'researchReport',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '调研报告助手',
    desc: '根据更换不同的类型，以产出适合自己需求的调研报告'
  },
  {
    id:'travelItineraryPlanning',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '旅游路线规划',
    desc: '根据旅行目的地、预算、时间等要求，粗略规划'
  },
  {
    id:'AIPsychotherapy',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: 'AI心理咨询师助手',
    desc: '为您提供一个深入、全面的AI心理咨询体验'
  },
  {
    id:'writingMaterial',
    img: require('../../assets/images/blessing.png'),
    type:'',
    text: '写作素材搜集',
    desc: '提供与主题相关的结论、数据和来源，作为素材'
  },
]